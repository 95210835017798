import { Types } from "./../../types";

export const mutations = {
  [Types.mutations.SET_LOADING_STATUS]: (state, payload) => {
    state.isLoading = payload;
  },
  [Types.mutations.SET_PROJECT_LIST]: (state, payload) => {
    state.projectList = [...payload];
    state.filteredProjectList = [...payload];
  },
  [Types.mutations.SET_TOKEN]: (state, payload) => {
    state.token = payload;
  },
  [Types.mutations.SET_MODAL_DETAIL]: (state, payload) => {
    state.modal = { ...state.modal, ...payload };
  },
  [Types.mutations.SET_PROJECT_FILTER]: (state, payload) => {
    state.filteredProjectList = [...payload];
  },
};
