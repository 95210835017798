export const state = () => ({
  projectDetail: {},
  token: "",
  isLoading: false,
  tables: {},
  tableList: [],
  modal: {
    showDialog: false,
    errorMessage: "",
  },
});
