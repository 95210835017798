<template>
  <div>
    <!-- Toolbar with Actions -->
    <v-toolbar dense flat>
      <v-toolbar-title>Project Detail</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="red" dark @click="deleteProjectDetail" icon>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn color="secondary" dark @click="goBack" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-toolbar>
    <LoaderComponent v-if="isLoading" />
    <template v-else>
      <TablesComponent :projectId="$route.params.projectId" />
      <UserComponent />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Types } from "./../store/types";
import LoaderComponent from "../components/LoaderComponent.vue";
import TablesComponent from "../components/TablesComponent.vue";
import UserComponent from "../components/UserComponent.vue";

export default {
  name: "ProjectDetailPage",
  components: {
    LoaderComponent,
    TablesComponent,
    UserComponent,
  },
  data() {
    return {
      projecId: "",
    };
  },
  async mounted() {
    await this.$msal.acquireToken();
    this.setToken(this.$msal.data.accessToken);
    this.projecId = this.$route.params.projectId;
    this.getProjectDetails(this.projecId);
  },
  computed: {
    ...mapGetters("projectDetail", {
      projectDetail: Types.getters.GET_PROJECT_DETAIL,
      isLoading: Types.getters.GET_LOADING_STATUS,
    }),
  },
  methods: {
    ...mapActions("projectDetail", {
      getProjectDetails: Types.actions.DO_PROJECT_DETAIL,
      setToken: Types.actions.DO_TOKEN,
      deleteProject: Types.actions.DO_DELETE_PROJECT,
    }),
    async deleteProjectDetail() {
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.deleteProject(this.projecId);
      !this.projectDetail.length && this.goBack();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
