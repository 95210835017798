import { HTTP } from "./../../../functions/http-common";
import { Types } from "./../../types";

export const actions = {
  [Types.actions.DO_LOADING_STATUS]: ({ commit }, payload) => {
    commit(Types.mutations.SET_LOADING_STATUS, payload);
  },
  [Types.actions.DO_TOKEN]: ({ commit }, payload) => {
    commit(Types.mutations.SET_TOKEN, payload);
  },
  [Types.actions.DO_MODAL_DETAIL]: ({ commit }, payload) => {
    commit(Types.mutations.SET_MODAL_DETAIL, payload);
  },
  [Types.actions.DO_PROJECT_DETAIL]: ({ dispatch, commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    state.isLoading = true;
    HTTP.get(`/projects/${payload}`, config)
      .then((result) => {
        commit(Types.mutations.SET_PROJECT_DETAIL, result.data);
        if (
          result.data &&
          result.data.contextTables &&
          result.data.contextTables.length
        )
          dispatch(Types.actions.DO_GET_TABLES, payload);
        else {
          state.isLoading = false;
          commit(Types.mutations.SET_TABLE_LIST, []);
        }
      })
      .catch((error) => {
        state.isLoading = false;
        throw new Error(`API ${error}`);
      });
  },
  [Types.actions.DO_DELETE_PROJECT]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    state.isLoading = true;
    HTTP.delete(`/projects/${payload}`, config)
      .then((result) => {
        state.isLoading = false;
        console.log("successfully deleted", result);
        commit(Types.mutations.SET_DELETE_PROJECT, {});
      })
      .catch((error) => {
        state.isLoading = false;
        console.log("error", error);
      });
  },
  [Types.actions.DO_ADD_USER]: ({ dispatch, commit, state }, payload) => {
    const requestId = state.projectDetail.id;
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    state.isLoading = true;
    HTTP.post(`/projects/${requestId}/users`, payload, config).then(
      (response) => {
        commit(Types.mutations.SET_MODAL_DETAIL, { showDialog: false });
        dispatch(Types.actions.DO_PROJECT_DETAIL, requestId);
        state.isLoading = false;
        console.log("Add User created successfully", response);
      },
      (error) => {
        state.isLoading = false;
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        console.log(error);
      },
    );
  },
  [Types.actions.DO_GET_TABLES]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    HTTP.get(`/projects/${payload}/tables`, config)
      .then((result) => {
        state.isLoading = false;
        commit(Types.mutations.SET_TABLES, result.data);
      })
      .catch((error) => {
        state.isLoading = false;
        throw new Error(`API ${error}`);
      });
  },
};
