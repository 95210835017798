import { Types } from "./../../types";

export const getters = {
  [Types.getters.GET_LOADING_STATUS]: (state) => {
    return state.isLoading;
  },
  [Types.getters.GET_PROJECT_LIST]: (state) => {
    return state.projectList;
  },
  [Types.getters.GET_MODAL_DETAIL]: (state) => {
    return state.modal;
  },
  [Types.getters.GET_FILTERED_PROJECT]: (state) => {
    return state.filteredProjectList;
  },
};
