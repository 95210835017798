import { Types } from "./../../types";
export const getters = {
  [Types.getters.GET_LOADING_STATUS]: (state) => {
    return state.isLoading;
  },
  [Types.getters.GET_PROJECT_DETAIL]: (state) => {
    return state.projectDetail;
  },
  [Types.getters.GET_MODAL_DETAIL]: (state) => {
    return state.modal;
  },
  [Types.getters.GET_TABLES]: (state) => {
    return state.tables;
  },
  [Types.getters.GET_TABLE_LIST]: (state) => {
    return state.tableList;
  },
};
