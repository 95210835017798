import { Types } from "./../../types";

export const mutations = {
  [Types.mutations.SET_LOADING_STATUS]: (state, payload) => {
    state.isLoading = payload;
  },
  [Types.mutations.SET_TOKEN]: (state, payload) => {
    state.token = payload;
  },
  [Types.mutations.SET_PROJECT_DETAIL]: (state, payload) => {
    state.projectDetail = { ...payload };
  },
  [Types.mutations.SET_DELETE_PROJECT]: (state, payload) => {
    state.projectDetail = payload;
  },
  [Types.mutations.SET_MODAL_DETAIL]: (state, payload) => {
    state.modal = { ...state.modal, ...payload };
  },
  [Types.mutations.SET_TABLES]: (state, payload) => {
    if (payload.length) {
      let temp = [];
      const list = payload.reduce((obj, item) => {
        if (obj[item.id] === undefined) {
          obj[item.id] = [];
        }
        item.qna.forEach((subItem) => {
          temp = [
            ...temp,
            {
              tableName: item.tableName,
              metadata: item.metadata,
              tableId: subItem.tableId,
              answer: subItem.answer,
              comment: subItem.comment,
              question: subItem.question,
              rowId: subItem.rowId,
            },
          ];
        });
        obj[item.id].push(...temp);
        return obj;
      }, {});
      state.tables = list;
    } else state.tables = {};
  },
  [Types.mutations.SET_TABLE_LIST]: (state, payload) => {
    state.tableList = payload;
  },
};
