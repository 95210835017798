export const Types = {
  getters: {
    GET_LOADING_STATUS: "GET_LOADING_STATUS",
    GET_PROJECT_LIST: "GET_PROJECT_LIST",
    GET_MODAL_DETAIL: "GET_MODAL_DETAIL",
    GET_PROJECT_DETAIL: "GET_PROJECT_DETAIL",
    GET_FILTERED_PROJECT: "GET_FILTERED_PROJECT",
    GET_TABLES: "GET_TABLES",
    GET_TABLE_LIST: "GET_TABLE_LIST",
  },
  mutations: {
    SET_PROJECT_LIST: "SET_PROJECT_LIST",
    SET_TOKEN: "SET_TOKEN",
    SET_MODAL_DETAIL: "SET_MODAL_DETAIL",
    SET_PROJECT_DETAIL: "SET_PROJECT_DETAIL",
    SET_PROJECT_FILTER: "SET_PROJECT_FILTER",
    SET_TABLES: "SET_TABLES",
    SET_TABLE_LIST: "SET_TABLE_LIST",
  },
  actions: {
    DO_PROJECT_LIST: "DO_PROJECT_LIST",
    DO_CREATE_PROJECT: "CREATE_PROJECT",
    DO_TOKEN: "DO_TOKEN",
    DO_MODAL_DETAIL: "DO_MODAL_DETAIL",
    DO_PROJECT_DETAIL: "DO_PROJECT_DETAIL",
    DO_PROJECT_FILTER: "DO_PROJECT_FILTER",
    DO_DELETE_PROJECT: "DO_DELETE_PROJECT",
    SET_DELETE_PROJECT: "SET_DELETE_PROJECT",
    DO_ADD_USER: "DO_ADD_USER",
    DO_GET_TABLES: "DO_GET_TABLES",
  },
};
