<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        style="-webkit-app-region: no-drag"
        color="gray02"
        size="40"
        v-bind="attrs"
        class="ma-2"
        v-on="on"
      >
        <img :src="userImage" />
      </v-avatar>
    </template>
    <v-list color="offwhite" width="200" dense>
      <v-list-item>
        <v-list-item-content
          v-if="this.$msal.isAuthenticated() && user.profile.displayName != ''"
        >
          <v-list-item-title>{{ user.profile.displayName }}</v-list-item-title>
          <v-list-item-subtitle v-if="!!user.profile.jobTitle">
            {{ user.profile.jobTitle }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="!!user.me.department"
            @click="pasteToken()"
          >
            {{ user.me.department }}
          </v-list-item-subtitle>
          <v-list-item-subtitle @click="testToken()">
            {{ user.me.country }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title>Not signed in</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-divider />
      <v-list-item v-if="this.$msal.isAuthenticated()" @click="$msal.signOut()">
        <v-list-item-title>Sign out</v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="$msal.signIn()">
        <v-list-item-title>Sign In</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { HTTP } from "../functions/http-common";
import { msalMixin } from "vue-msal";

export default {
  name: "AuthComponent",
  mixins: [msalMixin],
  data() {
    return {
      contentreply: "nothing",
      badgecolor: "gray02",
      accTokenCopy: "",
      userImage: "",
    };
  },
  computed: {
    user() {
      var userInfo = {};
      console.log("Fetch User Info");
      if (this.msal.isAuthenticated) {
        console.log("User is Authenticated", this.msal.isAuthenticated);
        userInfo = {
          ...this.msal.user,
          photoUrl: "",
          me: {
            department: "",
            country: "",
          },
          manager: {
            id: "",
          },
          name: "Hi, sign in...",
          userName: "",
          profile: {
            jobTitle: "",
            officeLocation: "",
            businessPhones: [],
            displayName: "",
            givenName: "",
            surname: "",
            preferredLanguage: "",
            id: "",
          },
        };
        if (this.msal.graph && this.msal.graph.profile && this.msal.graph.me) {
          userInfo.profile = this.msal.graph.profile;
          userInfo.manager = this.msal.graph.manager;
          userInfo.me = this.msal.graph.me;
        }
      } else {
        userInfo = {
          photoUrl: "",
          me: {
            department: "",
            country: "",
          },
          manager: {
            id: "",
          },
          name: "Hi, sign in...",
          userName: "",
          profile: {
            jobTitle: "",
            officeLocation: "",
            businessPhones: [],
            displayName: "",
            givenName: "",
            surname: "",
            preferredLanguage: "",
            id: "",
          },
        };
      }
      return userInfo;
    },
  },
  mounted() {
    this.$msal
      .msGraph([
        "/me",
        {
          url: "/me/photo/$value",
          responseType: "blob",
          id: "photo",
          force: true,
        },
      ])
      .then(
        (response) => {
          this.msal.graph.photo = new Blob([response.photo.body]);
          console.log(response);
          if (this.msal.graph.photo instanceof Blob) {
            this.user.photo = this.msal.graph.photo;
            try {
              const urlCreator = window.URL || window.webkitURL;
              const imageUrl = urlCreator.createObjectURL(this.user.photo);
              this.user.photoUrl = imageUrl;
              this.userImage = "data:image/jpeg;base64," + response.photo.body;
              console.log("Set profile image");
            } catch {
              console.log("Could not set profile image");
            }
          }
        },
        (error) => {
          console.log(error);
        },
      );
  },
  methods: {
    async getContent() {
      await this.$msal.acquireToken();
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`,
        },
      };
      this.accTokenCopy = this.$msal.data.accessToken;
      await HTTP.get("/user/me", config).then(
        (response) => {
          if (response.data.userid !== undefined) {
            this.contentreply = response.data;
            console.log(response.data);
            const { roles, me } = this.contentreply;
            this.msal.roles = roles;
            this.badgecolor =
              me.department !== undefined
                ? me.department == this.user.me.department
                  ? "green"
                  : "redlight"
                : "gray02";
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
    pasteToken() {
      window.Excel.run(async (context) => {
        var rangeAddress = "A1:B4";
        var range = context.workbook.worksheets
          .getActiveWorksheet()
          .getRange(rangeAddress);
        range.load("values");
        await context.sync();
        if (range.values.findIndex((element) => element[0] != "") == -1) {
          if (range.values.findIndex((element) => element[1] != "") == -1) {
            console.log("pasting");
            range.values = [
              ["User", this.user.profile.displayName],
              ["Department", this.user.me.department],
              ["Token", this.$msal.data.accessToken],
              ["Backend", this.$backend],
            ];
          }
        }
        await context.sync();
      });
    },
    signIn() {
      this.$msal.signIn().catch((error) => {
        console.log(error);
      });
    },
    async testToken() {
      let accessToken = await window.Office.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: true,
      });
      console.log(accessToken);
    },
  },
};
</script>
