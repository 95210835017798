<template>
  <v-card tile class="my-4">
    <!-- Card Head -->
    <v-row class="mt-0 pt-0">
      <v-col cols="10" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Tables
        </v-card-title>
      </v-col>
      <v-col cols="2" class="mt-2">
        <v-btn v-if="!hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-row class="mx-4 pa-0" v-if="hidden">
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="(value, key) in tableList"
            :key="key"
            :value="value"
            color="primary"
            link
            :to="`/projects/${projectId}/${key}`"
          >
            <v-list-item-content>
              <v-list-item-title
                >{{ value[0].tableName }} - {{ key }}</v-list-item-title
              >
              <v-list-item-subtitle>
                MetaData: sheetName - {{ value[0].metadata.sheet_name
                }}{{ value[0].metadata.file_name }} | Range -
                {{ value[0].metadata.upload_range }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>
    <v-row v-if="hidden">
      <v-col cols="10" xs="10"> </v-col>
      <v-col cols="2" xs="2">
        <v-btn icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { Types } from "./../store/types";

export default {
  name: "TablesComponent",
  data: () => ({
    hidden: true,
  }),
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("projectDetail", {
      tableList: Types.getters.GET_TABLES,
    }),
  },
  methods: {},
};
</script>
