<template>
  <v-card tile class="my-4">
    <v-row class="mt-0 pt-0">
      <v-col cols="8" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Users
        </v-card-title>
      </v-col>
      <v-col cols="2" class="mt-2">
        <v-btn
          color="primary"
          dark
          @click="updateModalDetail({ showDialog: true })"
          icon
        >
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="2" v-if="hidden" class="mt-2">
        <v-btn icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-simple-table v-if="!hidden">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">User ID</th>
            <th class="text-left">Roles</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in projectDetail.users" :key="user.userId">
            <td>{{ user.userId }}</td>
            <td>{{ getUserRole(user.role) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- Add User -->
    <div v-if="modalDetails.showDialog">
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Add User </template>
        <template v-slot:body>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="User ID"
                type="email"
                v-model="addUser"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="Object.keys(availableRoles)"
                density="compact"
                label="Roles"
                class="my-0"
                v-model="addRoles"
              ></v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-btn
            text
            @click="updateModalDetail({ showDialog: false })"
            style="font-size: 0.6rem"
          >
            Cancel
          </v-btn>
          <v-btn text @click="addUsers" style="font-size: 0.6rem"> Add </v-btn>
        </template>
      </ModalLayout>
    </div>
    <v-row v-if="!hidden">
      <v-col cols="10" xs="10"> </v-col>
      <v-col cols="2" xs="2">
        <v-btn icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ModalLayout from "./ModalLayout.vue";
import { mapActions, mapGetters } from "vuex";
import { Types } from "./../store/types";

export default {
  name: "UserComponent",
  components: {
    ModalLayout,
  },
  data: () => ({
    hidden: true,
    search: "",
    availableRoles: {
      Admin: ["admin", "read", "write"],
      Write: ["read", "write"],
      Read: ["read"],
    },
    addUser: "",
    addRoles: [],
  }),
  computed: {
    ...mapGetters("projectDetail", {
      projectDetail: Types.getters.GET_PROJECT_DETAIL,
      modalDetails: Types.getters.GET_MODAL_DETAIL,
    }),
  },
  methods: {
    ...mapActions("projectDetail", {
      setToken: Types.actions.DO_TOKEN,
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
      addNewUser: Types.actions.DO_ADD_USER,
    }),
    async addUsers() {
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.addNewUser({
        addUser: this.addUser,
        addRoles: this.availableRoles[this.addRoles],
      });
    },
    getUserRole(userRole) {
      let role = "";
      Object.keys(this.availableRoles).forEach((item) => {
        const result =
          !role &&
          this.availableRoles[item].every((value) => userRole.includes(value));
        if (result) {
          role = item;
        }
      });
      return role;
    },
  },
};
</script>
