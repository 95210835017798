import Vue from "vue";
import VueRouter from "vue-router";

import ProjectListPage from "./../views/ProjectListPage";
import PageNotFound from "./../views/PageNotFound";
import ProjectDetailPage from "./../views/ProjectDetailPage";
import TablePage from "./../views/TablePage";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "ProjectListPage",
      component: ProjectListPage,
    },
    {
      path: "/projects/:projectId",
      name: "ProjectDetailPage",
      component: ProjectDetailPage,
    },
    {
      path: "/projects/:projectId/:tableId",
      name: "TablePage",
      component: TablePage,
    },
    {
      path: "/:catchAll(.*)",
      component: PageNotFound,
      name: "PageNotFound",
    },
  ],
});

export default router;
