<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>Table Detail Page</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="secondary" dark @click="goBack" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="tableList[tableId]"
      :items-per-page="10"
      class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Types } from "./../store/types";

export default {
  name: "TablePage",
  data: () => ({
    tableId: "",
    headers: [
      { text: "Answer", value: "answer", sortable: false, align: "left" },
      { text: "Comment", value: "comment", sortable: false },
      { text: "Question", value: "question", sortable: false },
      { text: "Row Id", value: "rowId", sortable: false },
    ],
  }),
  computed: {
    ...mapGetters("projectDetail", {
      tableList: Types.getters.GET_TABLES,
    }),
  },
  async mounted() {
    this.tableId = this.$route.params.tableId;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
