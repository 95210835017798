import Vue from "vue";
import Vuex from "vuex";
import projectList from "./modules/projectList";
import projectDetail from "./modules/projectDetail";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    projectList,
    projectDetail
  },
});
