<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Project List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="showSearch"
          v-model="searchTerm"
          v-on:keyup="getFilteredList({ searchTerm, isAscending })"
          placeholder="Search projects..."
          class="mt-4"
          ref="searchField"
        ></v-text-field>
        <v-btn icon v-if="showSearch" @click="resetSearch()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn icon v-if="!showSearch" @click="resetSearch()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="
            isAscending = !isAscending;
            getFilteredList({ searchTerm, isAscending });
          "
        >
          <v-icon>{{ isAscending ? "mdi-arrow-up" : "mdi-arrow-down" }}</v-icon>
        </v-btn>
        <v-btn icon @click="updateModalDetail({ showDialog: true })">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <LoaderComponent v-if="isLoading" />
      <v-list v-else dense>
        <v-list-item-group>
          <v-list-item
            v-for="project in filteredList"
            :key="project.projectId"
            color="primary"
            class="d-block pl-5 mt-2"
            :value="project"
            link
            :to="`/projects/${project.projectId}`"
          >
            <v-list-item-content>
              <v-list-item-title>{{ project.projectName }}</v-list-item-title>

              <v-list-item-subtitle>
                {{ project.createdAt }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-divider></v-divider>
          </v-list-item>
          <v-list-item v-if="!filteredList.length">
            <p>No results found!</p>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <div v-if="modalDetails.showDialog">
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Create Project </template>
        <template v-slot:body>
          <v-text-field
            type="input"
            label="Project Name"
            v-model="projectName"
            hint="Enter the project name"
            clearable
          ></v-text-field>
        </template>
        <template v-slot:footer>
          <v-btn
            text
            @click="updateModalDetail({ showDialog: false })"
            style="font-size: 0.6rem"
          >
            Cancel
          </v-btn>
          <v-btn text @click="createNewProject()" style="font-size: 0.6rem">
            Submit
          </v-btn>
        </template>
      </ModalLayout>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalLayout from "../components/ModalLayout.vue";
import LoaderComponent from "../components/LoaderComponent.vue";
import { Types } from "./../store/types";

export default {
  name: "ProjectListPage",
  components: {
    ModalLayout,
    LoaderComponent,
  },
  data() {
    return {
      searchTerm: "",
      showSearch: false,
      isAscending: true,
      projectName: "",
    };
  },
  async mounted() {
    await this.$msal.acquireToken();
    this.setToken(this.$msal.data.accessToken);
    this.getProjectList();
  },
  computed: {
    ...mapGetters("projectList", {
      isLoading: Types.getters.GET_LOADING_STATUS,
      projectList: Types.getters.GET_PROJECT_LIST,
      modalDetails: Types.getters.GET_MODAL_DETAIL,
      filteredList: Types.getters.GET_FILTERED_PROJECT,
    }),
  },
  methods: {
    ...mapActions("projectList", {
      getProjectList: Types.actions.DO_PROJECT_LIST,
      setToken: Types.actions.DO_TOKEN,
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
      createProject: Types.actions.DO_CREATE_PROJECT,
      getFilteredList: Types.actions.DO_PROJECT_FILTER,
    }),
    resetSearch() {
      this.$refs.searchField && this.$refs.searchField.focus();
      this.showSearch = !this.showSearch;
      this.searchTerm = "";
      this.getFilteredList({ searchTerm: "" });
    },
    async createNewProject() {
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.createProject(this.projectName);
    },
  },
};
</script>
