<template>
  <v-card
    class="d-flex flex-column align-center justify-center"
    v-bind:style="{ height: deviceHeight * 0.6 + 'px' }"
  >
    <v-progress-circular
      indeterminate
      :size="80"
      :width="10"
    ></v-progress-circular>
  </v-card>
</template>

<script>
export default {
  name: "LoaderComponent",
  computed: {
    deviceHeight() {
      return this.$vuetify.breakpoint.height;
    },
  },
};
</script>
